import React from 'react'

const PrivacyPolicy = () => {
  return (
    <div padding="0">
    <div>
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" /><title>file_1648406515667</title><meta name="author" content="Windows User" />
        <style type="text/css" dangerouslySetInnerHTML={{__html: " * {margin:0; padding:0; text-indent:0; }\n h1 { color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: bold; text-decoration: underline; font-size: 19pt; }\n .s1 { color: #6394EC; font-family:\"Times New Roman\", serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 15pt; }\n .s2 { color: #6394EC; font-family:\"Times New Roman\", serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 15pt; }\n .s3 { color: black; font-family:\"Times New Roman\", serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 13.5pt; }\n .p, p { color: black; font-family:\"Times New Roman\", serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 13.5pt; margin:0pt; }\n h2 { color: black; font-family:\"Times New Roman\", serif; font-style: normal; font-weight: bold; text-decoration: none; font-size: 14pt; }\n .s4 { color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: bold; text-decoration: none; font-size: 19pt; }\n .s5 { color: #0462C1; font-family:\"Times New Roman\", serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 15pt; }\n .s6 { color: black; font-family:\"Times New Roman\", serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 10pt; }\n .s7 { color: #0462C1; font-family:\"Times New Roman\", serif; font-style: normal; font-weight: normal; text-decoration: underline; font-size: 15pt; }\n .s8 { color: #0462C1; font-family:\"Times New Roman\", serif; font-style: normal; font-weight: normal; text-decoration: underline; font-size: 15pt; }\n .s9 { color: #0462C1; font-family:\"Times New Roman\", serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 15pt; }\n" }} />
        <h1 style={{paddingTop: '3pt', paddingLeft: '37pt', textIndent: '0pt', textAlign: 'justify'}}>Privacy Policy</h1><p className="s2" style={{paddingTop: '14pt', paddingLeft: '37pt', textIndent: '0pt', lineHeight: '122%', textAlign: 'justify'}}>
        <a href="http://www.gglabs.in/" className="s1" target="_blank">Society of Chemical Engineers IIT </a>BHU 
        <a href="http://www.gglabs.in/" style={{color: 'black', fontFamily: '"Times New Roman", serif', fontStyle: 'normal', fontWeight: 'normal', textDecoration: 'none', fontSize: '13.5pt'}} target="_blank">built the SoChem app as a free app. This service is provided by </a>SoChem 
        <span className="s3">at no cost and is intended for use as is.</span></p>
        <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
        <p style={{paddingLeft: '37pt', textIndent: '0pt', lineHeight: '128%', textAlign: 'justify'}}>This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service.</p>
        <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p><p style={{paddingLeft: '37pt', textIndent: '0pt', lineHeight: '124%', textAlign: 'justify'}}>If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.</p>
        <h1 style={{paddingTop: '9pt', paddingLeft: '37pt', textIndent: '0pt', textAlign: 'justify'}}>About this app</h1><p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
        <p style={{paddingTop: '4pt', paddingLeft: '37pt', textIndent: '0pt', textAlign: 'justify'}}>It is developed to help the society (SoChem) by providing a digital platform for publicising the events and access to free online resources we have, such as notes, notifications, books.</p>
        <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p><h2 style={{paddingLeft: '49pt', textIndent: '0pt', textAlign: 'left'}}>FEED:</h2>
        <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p><p style={{paddingLeft: '37pt', textIndent: '0pt', lineHeight: '128%', textAlign: 'justify'}}>Students can know about the recent developments and announcements by our Society. These announcements can vary from Result declaration of any competitions we conduct to upcoming events and promotions of sponsors.</p>
        <h2 style={{paddingTop: '7pt', paddingLeft: '49pt', textIndent: '0pt', textAlign: 'left'}}>BOOKS/NOTES:</h2>
        <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p><p style={{paddingLeft: '37pt', textIndent: '0pt', lineHeight: '128%', textAlign: 'justify'}}>Books/lectures are uploaded in the form of PDF or images for specifically assigned subjects and genres. Students need to download the PDF/Images to view it.</p>
        <h2 style={{paddingTop: '7pt', paddingLeft: '49pt', textIndent: '0pt', textAlign: 'left'}}>TEAM MEMBERS:</h2><p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
        <p style={{paddingLeft: '37pt', textIndent: '0pt', lineHeight: '136%', textAlign: 'justify'}}>People can know about other members of the society and the team that is working hard to maintain these website and app along with helping us to ensure the smooth conductions of events.</p>
        <h1 style={{paddingTop: '3pt', paddingLeft: '37pt', textIndent: '0pt', textAlign: 'left'}}>Registration</h1><p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
        <p style={{paddingTop: '12pt', paddingLeft: '37pt', textIndent: '0pt', lineHeight: '123%', textAlign: 'justify'}}>There is user registration and login module. Users may register themselves for comprehensive access to the app by using their institute email Id. We don't provide the information to any third party agent. While registration the teachers and students are required to enter their email id, for cross verification through the database for genuine registration. The collection of these registrations resides securely on our servers.</p><p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
        <h1 style={{paddingLeft: '37pt', textIndent: '0pt', textAlign: 'justify'}}>OPT-OUT rights</h1>
        <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p><p style={{paddingTop: '4pt', paddingLeft: '37pt', textIndent: '0pt', lineHeight: '123%', textAlign: 'justify'}}>
        <a href="mailto:sochem.tech@gmail.com" style={{color: 'black', fontFamily: '"Times New Roman", serif', fontStyle: 'normal', fontWeight: 'normal', textDecoration: 'none', fontSize: '13.5pt'}} target="_blank">You can stop all collection of information by the Application easily by uninstalling the Application. You may use the standard uninstall processes which may be available as part of your mobile device or via the mobile application marketplace or network. You can also request to opt-out via email, at </a>
        <span style={{color: '#0462C1', fontFamily: '"Times New Roman", serif', fontStyle: 'normal', fontWeight: 'normal', textDecoration: 'underline', fontSize: '15pt'}}>sochem.tech@gmail.com</span>.</p>
        <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p><h1 style={{paddingTop: '4pt', paddingLeft: '37pt', textIndent: '0pt', lineHeight: '119%', textAlign: 'left'}}>Data Retention Policy, Managing<span className="s4"> </span>Your Information</h1>
        <p className="s5" style={{paddingTop: '6pt', paddingLeft: '37pt', textIndent: '0pt', lineHeight: '121%', textAlign: 'justify'}}>
        <a href="mailto:sochem.tech@gmail.com" style={{color: 'black', fontFamily: '"Times New Roman", serif', fontStyle: 'normal', fontWeight: 'normal', textDecoration: 'none', fontSize: '13.5pt'}} target="_blank">We will retain User provided data for as long as you use the Application and for a reasonable time thereafter. We will retain Automatically Collected information for up to 24 months and thereafter may store it in aggregate. If you’d like us to delete User Provided Data that you have provided   via   the   Application,   please   contact   us   at </a>
        <span style={{color: '#0462C1', fontFamily: '"Times New Roman", serif', fontStyle: 'normal', fontWeight: 'normal', textDecoration: 'underline', fontSize: '15pt'}}>sochem.tech@gmail.com</span> 
        <span className="p">and we will respond in a reasonable time. Please note that some or all of the User Provided Data may be required in order for the Application to function properly.</span></p>
        <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p><h1 style={{paddingLeft: '37pt', textIndent: '0pt', textAlign: 'left'}}>Advertising</h1>
        <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p><p style={{paddingTop: '4pt', paddingLeft: '37pt', textIndent: '0pt', textAlign: 'left'}}>This App doesn't contain any ads</p>
        <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p><h1 style={{paddingLeft: '37pt', textIndent: '0pt', textAlign: 'left'}}>Children’s Policy</h1>
        <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p><p style={{paddingTop: '4pt', paddingLeft: '37pt', textIndent: '0pt', lineHeight: '134%', textAlign: 'left'}}>As this app is designed for use of college students hence our users will be of age 18 and above.</p>
        <p style={{paddingTop: '3pt', paddingLeft: '37pt', textIndent: '0pt', lineHeight: '134%', textAlign: 'justify'}}>We recognize the need to provide further privacy protections with respect to the personal information that we may receive relating to children on our Website and Application. When we receive personal information relating to children, we take additional steps to protect children’s privacy, including - Notifying parents about our information practices with regard to children, including the types of personal information we may collect related to children,
         the uses to which we may put that information, and whether and with whom we may share that information; In accordance with applicable law, and our practices, obtaining consent from parents for the collection of personal information of their children, or for sending information about our products and services directly to their children; Limiting our collection of personal information relating to children to no more than is reasonably necessary to participate in our Services.</p><p className="s6" style={{paddingLeft: '5pt', textIndent: '0pt', lineHeight: '10pt', textAlign: 'left'}}>-</p>
         <h1 style={{paddingLeft: '37pt', textIndent: '0pt', lineHeight: '22pt', textAlign: 'justify'}}>Cookies &amp; unique Token number</h1><p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
         <p className="s5" style={{paddingTop: '4pt', paddingLeft: '37pt', textIndent: '0pt', lineHeight: '120%', textAlign: 'justify'}}>
         <a href="http://www.sochem.org/" style={{color: 'black', fontFamily: '"Times New Roman", serif', fontStyle: 'normal', fontWeight: 'normal', textDecoration: 'none', fontSize: '13.5pt'}} target="_blank">Cookies are small pieces of information that are stored in a browser-related file on your computer's hard drive when you use our Web site. We use cookies to improve the member experience, like allowing the member to login without typing the registered email address or registration number and password. We also use them to deliver information and fresh content specific to your interests. 
         Cookies are widely  used  on  most  major  Web  sites.  When  you  register </a>
         <span style={{color: '#0462C1', fontFamily: '"Times New Roman", serif', fontStyle: 'normal', fontWeight: 'normal', textDecoration: 'underline', fontSize: '15pt'}}>www.sochem.org</span> 
         <span className="p">uses a cookie to store a unique, random user ID. We do not store passwords or any personal information about you. We use this ID to identify you anonymously in our database and to track the pages you visit on our site. Cookies also enable you to enter the sites and use services as a member without having to log in each time. If you have set your browser to warn you before accepting cookies, you will receive the warning message with each cookie. You can refuse cookies by turning them off in your browser.</span></p><p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
         <h1 style={{paddingLeft: '37pt', textIndent: '0pt', textAlign: 'justify'}}>Information Disclaimer</h1>
         <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
         <p style={{paddingTop: '4pt', paddingLeft: '37pt', textIndent: '0pt', lineHeight: '128%', textAlign: 'justify'}}>The information provided on the app has been collected from Society heads, teachers and students. Lots of information has been taken from related educational sites, books</p>
         <p style={{paddingTop: '3pt', paddingLeft: '41pt', textIndent: '0pt', lineHeight: '121%', textAlign: 'justify'}}><a href="mailto:sochem.tech@gmail.com" style={{color: 'black', fontFamily: '"Times New Roman", serif', fontStyle: 'normal', fontWeight: 'normal', textDecoration: 'none', fontSize: '13.5pt'}} target="_blank">and other sources. It is presumed that we are not violating any copyrights. There is no intention to violate intellectual copyright or take credit for any establishment included in the databases. However, if any Institution has any objection, the same may be brought immediately to the notice of </a>
         <span style={{color: '#0462C1', fontFamily: '"Times New Roman", serif', fontStyle: 'normal', fontWeight: 'normal', textDecoration: 'underline', fontSize: '15pt'}}>sochem.tech@gmail.com</span>. We will ensure immediate Legal opinion on explicit observation/complaint and take immediate steps to legalize it, if required.</p>
         <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
         <p className="s8" style={{paddingLeft: '37pt', textIndent: '0pt', lineHeight: '122%', textAlign: 'justify'}}><a href="http://www.sochem.org/" className="s7" target="_blank">www.sochem.or</a>g <a href="http://www.sochem.org/" style={{color: 'black', fontFamily: '"Times New Roman", serif', fontStyle: 'normal', fontWeight: 'normal', textDecoration: 'none', fontSize: '13.5pt'}} target="_blank">is not expected to offend anyone. However if any link to any Institute offends any surfer we at </a>www.sochem.org<span className="s9"> </span><span className="s3">take no responsibility concerning the impact or consequence of the same.</span></p>
         <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p><p className="s5" style={{paddingLeft: '37pt', textIndent: '0pt', lineHeight: '118%', textAlign: 'justify'}}><a href="http://www.sochem.org/" style={{color: 'black', fontFamily: '"Times New Roman", serif', fontStyle: 'normal', fontWeight: 'normal', textDecoration: 'none', fontSize: '13.5pt'}} target="_blank">While we have made every effort to ensure the accuracy and authenticity of the information presented in this site, </a><span style={{color: '#0462C1', fontFamily: '"Times New Roman", serif', fontStyle: 'normal', fontWeight: 'normal', textDecoration: 'underline', fontSize: '15pt'}}>www.sochem.org</span>
         <span className="p">cannot be held liable for any errors or inconsistencies.</span></p><p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p><h1 style={{paddingTop: '11pt', paddingLeft: '37pt', textIndent: '0pt', textAlign: 'left'}}>Changes</h1><p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
         <p style={{paddingTop: '4pt', paddingLeft: '37pt', textIndent: '0pt', lineHeight: '124%', textAlign: 'justify'}}>This Privacy Policy may be updated from time to time for any reason. We will notify you of any changes to our Privacy Policy by posting the new Privacy Policy here and informing you via email or text message. You are advised to consult this Privacy Policy regularly for any changes, as continued use is deemed approval of all changes.</p><p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
         <h1 style={{paddingTop: '10pt', paddingLeft: '37pt', textIndent: '0pt', textAlign: 'justify'}}>Contact us</h1><p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
         <p style={{paddingTop: '4pt', paddingLeft: '37pt', textIndent: '0pt', lineHeight: '118%', textAlign: 'justify'}}><a href="mailto:gglabsnew@gmail.com" style={{color: 'black', fontFamily: '"Times New Roman", serif', fontStyle: 'normal', fontWeight: 'normal', textDecoration: 'none', fontSize: '13.5pt'}} target="_blank">If you have any questions about this privacy statement, the practices of this  site  or  your  dealings  with  this  website  contact  us  at </a>
         <span style={{color: '#6394EC', fontFamily: '"Times New Roman", serif', fontStyle: 'normal', fontWeight: 'normal', textDecoration: 'none', fontSize: '15pt'}}>sochem.tech@gmail.com</span>.</p>
         <h1 style={{paddingTop: '9pt', paddingLeft: '37pt', textIndent: '0pt', textAlign: 'justify'}}>Link to app</h1><p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
         <p style={{paddingTop: '4pt', paddingLeft: '37pt', textIndent: '0pt', textAlign: 'left'}}>(to be inserted after the app gets uploaded on playstore)</p>
      </div>
    {/* <iframe src="./components/assets/docs/sochem_privacy_policy.pdf" title="privacy_policy" height="800" width="100%"></iframe> */}
    </div>
  );
}

export default PrivacyPolicy